// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const isPreLaunchMode = true;

export const environment = {
  production: false,
  playerDashboardUrl: 'https://development-play.ready.gg/',
  url: {
    gen1: {
      base: 'https://us-central1-readymaster-development.cloudfunctions.net/',
    },
    gen2: {
      prefix: 'https://',
      sufix: 'uc.a.run.app',
      project: 'ehfdkpcyaq',
    },
    // TODO: remove next entry?
    project: 'ehfdkpcyaq',
  },
  projectId: '8McbPa7yR4ozLut3udHf',
  firebaseIcon: {
    apiKey: 'AIzaSyC0-w_bk6QogH_Szk4uFjgz_LJwPLeV870',
    authDomain: 'icon-staging.firebaseapp.com',
    databaseURL: 'https://icon-staging-default-rtdb.firebaseio.com',
    projectId: 'icon-staging',
    storageBucket: 'icon-staging.appspot.com',
    messagingSenderId: '593004793135',
    appId: '1:593004793135:web:96ed8f616c0f0cd44f65da',
    measurementId: 'G-SCPP0BCPKL',
  },
  firebaseMaster: {
    apiKey: 'AIzaSyCGOTTKpb_3uKH1cEJRuUx2_ByMWRQ5E5w',
    authDomain: 'readysandbox.firebaseapp.com',
    databaseURL: 'https://readysandbox.firebaseio.com',
    projectId: 'readysandbox',
    storageBucket: 'readysandbox.appspot.com',
    messagingSenderId: '161041084576',
    appId: '1:161041084576:web:e0995202a7494a1e1e19c5',
    measurementId: 'G-0MFJ0EB6ZN',
  },
  firebaseTriggerMaster: {
    apiKey: 'AIzaSyB6ZiW7WiRUEwIEVbdrH_MA4r9egmGGtzw',
    authDomain: 'triggermasters-staging.firebaseapp.com',
    databaseURL: 'https://triggermasters-staging-default-rtdb.firebaseio.com',
    projectId: 'triggermasters-staging',
    storageBucket: 'triggermasters-staging.appspot.com',
    messagingSenderId: '331947461925',
    appId: '1:331947461925:web:be8956f871ce6ea63e9ecc',
    measurementId: 'G-V76X4ZFEL8',
  },
  firestoragePath: 'gs://readysandbox.appspot.com',
  firebaseTestWebAppProxyCX: {
    apiKey: 'AIzaSyBQipqHOVSSQGWiVzO2DAIqd1pqNt9Zy90',
    authDomain: 'nouveauproxycx.firebaseapp.com',
    projectId: 'nouveauproxycx',
    storageBucket: 'nouveauproxycx.appspot.com',
    messagingSenderId: '101136790880',
    appId: '1:101136790880:web:180504b470ed135c36da05',
  },
  readyGamesDeveloper: {
    apiKey: 'AIzaSyAIa7a_dTdTuswSoPWwZwGc5uovzvo-IgE',
    authDomain: 'rgn-web-staging.firebaseapp.com',
    projectId: 'rgn-web-staging',
    storageBucket: 'rgn-web-staging.appspot.com',
    messagingSenderId: '386847254893',
    appId: '1:386847254893:web:199953233770f407f35264',
    measurementId: 'G-1QZXMMRSY5',
  },
  smart_contract: {
    chain: 'mumbai',
    adminAddress: '0x4c6348bf16FeA56F3DE86553c0653b817bca799A',
    auraAddress: '0x03862b4fC815bf15Aa442C58F090F4D8aC739649',
    fuseBlockAddress: '0xadab77264f77EE0871eD0ecf8Fec2cBC167A8E3E',
    itemAddress: '0xF77C50F688ccCd5936E77f9dc5fA72234dB29610',
    stakeAddress: '0x80FB7b999eBd7c91ba0fd258Da4C1489Acd629EB',
  },
  auth: {
    url: 'https://development-oauth.myplay.network',
  },
};
